<template>
  <!--评论-->
  <div class="comment">
    <music-loading v-model="isShowLoad" />
    <dl v-if="commentList.length > 0" class="comment-list" @scroll="listScroll($event)">
      <dt class="comment-title">歌曲评论（共{{ total }}条）</dt>
      <dd v-for="item in commentList" :key="item.commentId" class="comment-item">
        <a class="comment-item-pic" target="_blank" :href="`https://music.163.com/#/user/home?id=${item.user.userId}`">
          <img v-lazy="`${item.user.avatarUrl}?param=50y50`" class="cover-img" />
        </a>
        <h2 class="comment-item-title">
          <a target="_blank" :href="`https://music.163.com/#/user/home?id=${item.user.userId}`">
            {{ item.user.nickname }}
          </a>
        </h2>
        <p class="comment-item-disc">{{ item.content }}</p>
        <div class="comment-item-opt">
          <span class="comment-opt-date">{{ item.time | format }}</span>
          <span v-if="item.likedCount >= 0" class="comment-opt-liked">
            <music-icon type="good" />
            {{ item.likedCount }}
          </span>
        </div>
        <div v-for="beReplied in item.beReplied" :key="beReplied.user.userId" class="comment-item-replied">
          <a class="comment-item-replied-pic" target="_blank" :href="`https://music.163.com/#/user/home?id=${beReplied.user.userId}`">
            <img v-lazy="`${beReplied.user.avatarUrl}?param=50y50`" class="cover-img" />
          </a>
          <h2 class="comment-item-replied-title">
            <a target="_blank" :href="`https://music.163.com/#/user/home?id=${beReplied.user.userId}`">
              {{ beReplied.user.nickname }}
            </a>
          </h2>
          <p class="comment-item-replied-disc">{{ beReplied.content }}</p>
        </div>
      </dd>
    </dl>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getComment } from 'api'
  import { addZero } from '@/utils/util'
  import { loadMixin } from '@/utils/mixin'
  import MusicLoading from 'components/music-common/loading/music-loading'

  export default {
    name: 'Comment',
    components: {
      MusicLoading
    },
    filters: {
      // 格式化时间
      format(time) {
        let formatTime
        const date = new Date(time)
        const dateObj = {
          year: date.getFullYear(),
          month: date.getMonth(),
          date: date.getDate(),
          hours: date.getHours(),
          minutes: date.getMinutes()
        }
        const newTime = new Date()
        const diff = newTime.getTime() - time
        if (newTime.getDate() === dateObj.date && diff < 60000) {
          formatTime = '刚刚'
        } else if (
          newTime.getDate() === dateObj.date &&
          diff > 60000 &&
          diff < 3600000
        ) {
          formatTime = `${Math.floor(diff / 60000)}分钟前`
        } else if (
          newTime.getDate() === dateObj.date &&
          diff > 3600000 &&
          diff < 86400000
        ) {
          formatTime = `${addZero(dateObj.hours)}:${addZero(dateObj.minutes)}`
        } else if (newTime.getDate() !== dateObj.date && diff < 86400000) {
          formatTime = `昨天${addZero(dateObj.hours)}:${addZero(dateObj.minutes)}`
        } else if (newTime.getFullYear() === dateObj.year) {
          formatTime = `${dateObj.month + 1}月${dateObj.date}日`
        } else {
          formatTime = `${dateObj.year}年${dateObj.month + 1}月${dateObj.date}日`
        }
        return formatTime
      }
    },
    mixins: [loadMixin],
    computed: {
      ...mapGetters(['playlist', 'currentMusic'])
    },
    data() {
      return {
        lockUp: true, // 滚动加载
        commentList: [], // 评论
        page: 0, // 分页
        total: null // 评论总数
      }
    },
    watch: {
      commentList(newList, oldList) {
        if (newList.length !== oldList.length) {
          this.lockUp = false
        }
      }
    },
    created() {
      document.title = `歌曲评论 - ${this.currentMusic.name}`
      this.initData()
    },
    methods: {
      initData() {
        getComment(this.$route.params.id, this.page).then(res => {
          this.commentList = res.comments
          this.total = res.total
          this.lockUp = true
          this._hideLoad()
        })
      },
      // 列表滚动事件
      listScroll(e) {
        if (this.lockUp) {
          return
        }
        const {
          scrollTop,
          scrollHeight,
          offsetHeight
        } = e.target
        if (scrollTop + offsetHeight >= scrollHeight - 100) {
          this.lockUp = true
          this.page += 1
          this.pullUp()
        }
      },
      // 滚动加载事件
      pullUp() {
        getComment(this.$route.params.id, this.page).then(({
          comments
        }) => {
          this.commentList = [...this.commentList, ...comments]
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .comment {
    position: relative;
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    overflow: hidden;

    .comment-list {
      height: 100%;
      padding: 0 10px;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      .comment-title {
        height: 30px;
        line-height: 30px;
        padding: 10px 0;
        text-align: left;
        font-size: 16px;
        font-weight: 600;
        color: @text_color_active;
        border-bottom: 1px solid @comment_item_line_color;
      }

      .comment-item {
        position: relative;
        padding: 15px 0 15px 55px;
        border-bottom: 1px solid @comment_item_line_color;

        .comment-item-pic {
          display: block;
          position: absolute;
          left: 0;
          top: 20px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          transition: all 1s ease-in-out;
        }
        .comment-item-pic:hover {
          transform: rotate(360deg);
        }

        .comment-item-title {
          height: 20px;
          margin-bottom: 6px;
          font-weight: 400;
          .no-wrap();
          color: @text_color_active;
        }

        .comment-item-disc {
          overflow: hidden;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 25px;
          text-align: justify;
          color: @text_color;

          img {
            position: relative;
            vertical-align: middle;
            top: -2px;
          }
        }

        .comment-item-replied {
          padding: 8px 19px;
          margin-top: 10px;
          line-height: 20px;
          padding: 15px 0 15px 60px;
          border-top: 1px dotted @comment_replied_line_color;

          a {
            color: @text_color_active;
          }
        }
        .comment-item-replied-pic {
          display: block;
          position: absolute;
          left: 65px;
          top: 130px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          transition: all 1s ease-in-out;
        }
        .comment-item-replied-pic:hover {
          transform: rotate(360deg);
        }
        .comment-item-replied-title {
          height: 20px;
          margin-bottom: 6px;
          font-weight: 400;
          .no-wrap();
          color: @text_color_active;
        }

        .comment-item-replied-disc {
          overflow: hidden;
          word-break: break-all;
          word-wrap: break-word;
          line-height: 25px;
          text-align: justify;
          color: @text_color;

          img {
            position: relative;
            vertical-align: middle;
            top: -2px;
          }
        }

        .comment-item-opt {
          margin-top: 10px;
          line-height: 25px;
          text-align: right;
          overflow: hidden;

          .comment-opt-date {
            float: left;
            line-height: 28px;
          }

          .comment-opt-liked {
            display: inline-block;
            height: 20px;
            line-height: 20px;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
    background: #757F9A;
    background: -webkit-linear-gradient(to right, #D7DDE8, #757F9A);
    background: linear-gradient(to right, #D7DDE8, #757F9A);
    border-radius: 10px;
    transition: all 0.8s;
  }
  .comment:hover ::-webkit-scrollbar-thumb {
    visibility: visible;
  }
</style>
